.ghost-details {
    height: 100%;
}

.ghost-list {
    columns: 5;
}

@media screen and (max-width: 1350px) {
    .ghost-list {
        columns: 4;
    }
}

@media screen and (max-width: 1200px) {
    .ghost-list {
        columns: 3;
    }
}

@media screen and (max-width: 1000px) {
    .ghost-list {
        columns: 2;
    }
}

@media screen and (max-width: 500px) {
    .ghost-list {
        columns: 1;
    }
}

.game-icon {
    font-size: 150%;
}

.footsteps-stats {
    width: 11rem;
}

.footsteps-chart {
    width: 100%;
    height: 75px;
}

.speed-highlighted .on-ghost-state, .speed-highlighted .indetermined-ghost-state {
    background: darkmagenta;
    padding: 0.3rem;
}

.border-none {
    border-color: transparent !important;
}